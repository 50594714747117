import React from 'react'
import styled from '@emotion/styled';
import { Box, Container, Grid } from '@mui/material';
import Regtxt from '../../Regtxt';
import paalai from '../../../../assets/Images/PaalAI.png'
import CryptoAutos from '../../../../assets/Images/CryptoAutos.png'
import Spectre from '../../../../assets/Images/SpectreAI.png'
import tatsu from '../../../../assets/Images/tatsu.png'
import QFnetwork from '../../../../assets/Images/QFnetwork.png'
import Hussby from '../../../../assets/Images/HUSBY.png'
import NfinityAI from '../../../../assets/Images/NfinityAI.png'
import Defender from '../../../../assets/Images/DefenderBot.png'
import Synk from '../../../../assets/Images/Synk.png'
import Bank from '../../../../assets/Images/BankAI.png'
import SectBot from '../../../../assets/Images/SectBot.png'
import Otsea from '../../../../assets/Images/Otsea.png'

// import Neonai from '../../../../assets/Images/newDesign/Neonai.png'
// import Xtrack from '../../../../assets/Images/newDesign/Xtrack.png'
// import Toadswap from '../../../../assets/Images/newDesign/Toadswap.png'
// import Bank from '../../../../assets/Images/newDesign/Bank AI.png'
// import CLS from '../../../../assets/Images/newDesign/CLS.png'
// import Defender from '../../../../assets/Images/newDesign/Defender Bot.png'
// import zkHive from '../../../../assets/Images/newDesign/zkHive.png'
// import Resistor from '../../../../assets/Images/newDesign/Resistor Ai.png'
import Partners from '../../Partnershiptitle';
import Paragraph from '../../Paragraph';
const  Contbg = styled(Box)` 
min-height:  ${(props) => props.noht ? "auto" : "300px"} ;
display: flex; 
flex-direction: column; 
justify-content: start;
align-items: center;
width: 100%;
background: linear-gradient(to right,#090909,#000000);
border-radius: 10px; 
gap:70px; 
padding: 70px 0px;
 `  
   const BoxLay = styled(Box)`
   max-width: 100%; 
   min-height: auto; 
   display:flex; 
   flex-direction: row; 
   justify-content: start; 
   background-color: #161616;  
   padding: 30px  40px; 
   gap: 20px; 
   border-radius: 7px;
 ` 
const  Partnership = () => { 

  return (
    <Contbg>   
    <Container maxWidth="lg" data-aos="fade-up" sx={{position:"relative",zIndex:"500",padding:{xs:"35px 30px "}}}>   
         <Paragraph variant="h4" fontSize="clamp(1.875rem, 1.4464rem + 2.1429vw, 3.375rem)" mb="40px" fontWeight="600" >Our Partners</Paragraph>
        <Grid container padding="0 10px" display='flex' flexWrap="wrap" justifyContent='center' gap="30px 70px">
         <Partners psrc={paalai}  def1="Paal AI"  /> 
         <Partners psrc={CryptoAutos}  def1="Crypto Autos"  />
         <Partners psrc={Spectre}   def1="Spectre"   /> 
         <Partners psrc={tatsu}  def1="Tatsu"   />
         <Partners psrc={QFnetwork}  def1="QFnetwork"   />
         <Partners psrc={Hussby}  def1="Husby"   />
         <Partners psrc={NfinityAI}  def1="NfinityAI"   />
         <Partners psrc={Defender}  def1="Defender"   />
         <Partners psrc={Synk}  def1="Synk"   />
         <Partners psrc={Bank}  def1="Bank"   />
         <Partners psrc={SectBot}  def1="SectBot"   />
         <Partners psrc={Otsea}  def1="otsea"   />
         {/* <Partners psrc={Bank}  def1="Bank AI" imgw="83px"  />
         <Partners psrc={Defender}  def1="Defender Bot" imgw="83px"  />
         <Partners psrc={tatsu}  def1="TATSU" imgw="83px"  /> */}
        </Grid>
    </Container>
    </Contbg>
  )
}

export default  Partnership